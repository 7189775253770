import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {UserActions} from './euroports.actions';
import {User} from '../../api/models/user';
import {UserRole} from '../../api/models/user-role';
import {UserApiService} from '../../api/services/user-api.service';

export type UserStateModel = {
  user?: User
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: undefined
  }
})
@Injectable()
export class UserState {

  constructor(private userApiService: UserApiService) {
  }

  @Selector()
  static currentUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static currentUserServices(state: UserStateModel) {
    return state.user?.services;
  }

  @Selector()
  static isAdmin(state: UserStateModel) {
    return UserState.hasRole(state, 'ADMIN');
  }

  @Selector()
  static isDirector(state: UserStateModel) {
    return UserState.hasRole(state, 'DIRECTOR');
  }

  @Selector()
  static isQhse(state: UserStateModel) {
    return UserState.hasRole(state, 'QHSE');
  }

  @Selector()
  static isAccountant(state: UserStateModel) {
    return UserState.hasRole(state, 'ACCOUNTANT');
  }

  private static hasRole(state: UserStateModel, role: UserRole) {
    return state.user?.roles?.includes(role) ?? false;
  }

  @Action(UserActions.Load)
  loadUser(ctx: StateContext<UserStateModel>) {
    this.userApiService.getConnectedUser()
      .subscribe(user => {
        ctx.setState({ user });
      });
  }
}
